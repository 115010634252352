/**
CREATIVE CODING
---
Kill server : CTRL + C
Start server : npm run start
Start secure server : npm run start-https
Final build : npm run build
---
To generate new certificate for https connection with external device run :
#sh
mkcert 0.0.0.0 localhost 127.0.0.1 yourLocalIP ::1
mv 0.0.0.0+4-key.pem certificate.key
mv 0.0.0.0+4.pem certificate.cert
**/

// import Playground from "@onemorestudio/playgroundjs";
export default class App {
  constructor() {
    document.body.style.backgroundImage = "url('./images/BillieEilish.jpg')";
    // this.draw();

    this.countdown = document.getElementById("countdown");
    this.countdown.innerHTML = "countdown";
    this.final_countdown = new Date("2025-06-10T19:00:00").getTime();
    this.draw();
  }

  draw() {
    // Get today's date and time
    let now = new Date().getTime();
    // Find the distance between now and the count down date
    let distance = this.final_countdown - now;
    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    // add leading zero to hours
    if (hours < 10) {
      hours = "0" + hours;
    }
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    // add leading zero to minutes
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    // add leading zero to seconds
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    // Display the result in the element with id="demo"
    this.countdown.innerHTML = `${days} <span>J</span> ${hours} : ${minutes} : ${seconds}`;
    requestAnimationFrame(this.draw.bind(this));
  }
}
